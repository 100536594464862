import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import styles from "./plaquster.module.scss";

import arrowIconOrange from '../images/svg-icons/arrow-orange.svg'

import "./plaquster-customizations.scss";

export default (props) => {

	const {
		data: {
			plaquster,
			plaquster: {
				ACFPlaquster: {
					plaqusterTitle,
					plaqusterContent: {
						learnMoreLink,
						textContent,
						plaqusterImage,
						coachMolarTip: {
							coachMolarImage,
							tipText
						}
					},
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						}
					}
				}
			},
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
		},
		pageContext
	} = props;

	//pagination
	const { prev, next } = pageContext;

	const plaqusterCssClass = plaqusterTitle.toLowerCase().split('').join('').replace(' ', '').replace(/[^a-zA-Z ]/g, "")

	return (
		<Layout noFooter={true} headerNavHasDarkTheme={true}>
			<Seo post={plaquster} />  
			<div className={`${styles.hero} ${plaqusterCssClass} ${!plaqusterImage && 'noimg'}`}>
				<div className={`${styles.heroOverlay} heroOverlay`}>{/* Extra css class is for customizations scss file */}</div>
				{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styles.bgmobile} loading={"eager"} /> }
				{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styles.bgtablet} loading={"eager"} /> }
				{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styles.bgdesktop} loading={"eager"} /> }
				{ introBGXL && <FluidImage image={introBGXL} passedClass={styles.bgxl} loading={"eager"} /> }
				{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}

				<WPLink link={{target: '', title: 'Make an Appointment', url: '/schedule-online/'}} passedClass={styles.mobileapptlink} passedIconClass={styles.icon} />

				
				<div className={`${styles.plaqustercontent} plaqustercontent`}>
					<h1 className={`${styles.plaqustertitle} plaqustertitle`} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">{plaqusterTitle}</h1>
					<div className={`${styles.contentwrap} contentwrap`}>
						{plaqusterImage && <div className={`${styles.plaqusterImage} plaqusterimage`} data-sal="slide-up" data-sal-delay="800" data-sal-easing="ease-out"><div className="imgwrap">
							<FluidImage image={plaqusterImage} passedClass={styles.characterImage} loading={"eager"} />
							</div></div>
						}
						<div className={`${styles.textcontent} textcontent`} data-sal="slide-up" data-sal-delay="500" data-sal-easing="ease-in">
							<div>
							<div dangerouslySetInnerHTML={{ __html: textContent }} />
								{learnMoreLink && <div className="learnmorelinkwrap"><WPLink 
									link={{target: learnMoreLink.target, title: learnMoreLink.title, url: learnMoreLink.url}} 
									passedClass={styles.learnmorelink}  
									icon={arrowIconOrange} 
									iconIsSVG={true}	
								/></div>}
							</div>
						</div>
					</div>

					
					<nav className={styles.paginationnav}>

						{prev && <div><Link to={prev.link} className={`${styles.prevPagLink} prevPageLink`} rel="prev"> 
							<svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11">
									<g fill="none" fillRule="evenodd">
										<g fill="#FBB03B" className={`${styles.arrowfill} arrowfill`}>
											<g>
												<g>
													<path d="M188.638 11.04l-2.44-2.452c-.336-.339-.882-.339-1.218 0-.337.339-.337.887 0 1.226l3.91 3.932c.04.04.085.076.132.108.02.013.04.022.06.033.03.016.058.034.089.047.025.01.05.016.077.024.028.009.055.02.084.025.044.009.089.012.133.013.012 0 .023.004.035.004.013 0 .026-.004.039-.004.043-.002.086-.004.129-.013.03-.006.059-.017.088-.027.025-.007.05-.013.073-.022.033-.014.064-.033.095-.05.018-.01.036-.018.053-.03.048-.032.093-.068.133-.109l3.91-3.931c.169-.17.253-.391.253-.613 0-.222-.084-.444-.253-.613-.336-.339-.882-.339-1.219 0l-2.439 2.453V5.867c0-.479-.386-.867-.862-.867s-.862.388-.862.867v5.174z" transform="translate(-535 -751) translate(35 45) translate(315 702) rotate(-90 189.5 9.5)"/>
												</g>
											</g>
										</g>
									</g>
								</svg> 
								Back
							</Link></div>
						}

						
						{!next && <div><Link to="/plaquster/intro/" className={`${styles.nextPagLink} nextPageLink`} rel="next"> 
							Start Over
							<svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11">
									<g fill="none" fillRule="evenodd">
										<g fill="#FBB03B" className={`${styles.arrowfill} arrowfill`}>
											<g>
												<g>
													<path d="M188.638 11.04l-2.44-2.452c-.336-.339-.882-.339-1.218 0-.337.339-.337.887 0 1.226l3.91 3.932c.04.04.085.076.132.108.02.013.04.022.06.033.03.016.058.034.089.047.025.01.05.016.077.024.028.009.055.02.084.025.044.009.089.012.133.013.012 0 .023.004.035.004.013 0 .026-.004.039-.004.043-.002.086-.004.129-.013.03-.006.059-.017.088-.027.025-.007.05-.013.073-.022.033-.014.064-.033.095-.05.018-.01.036-.018.053-.03.048-.032.093-.068.133-.109l3.91-3.931c.169-.17.253-.391.253-.613 0-.222-.084-.444-.253-.613-.336-.339-.882-.339-1.219 0l-2.439 2.453V5.867c0-.479-.386-.867-.862-.867s-.862.388-.862.867v5.174z" transform="translate(-535 -751) translate(35 45) translate(315 702) rotate(-90 189.5 9.5)"/>
												</g>
											</g>
										</g>
									</g>
								</svg> 
							</Link></div>
						}

						{next && <div><Link to={next.link} className={`${styles.nextPagLink} nextPageLink`} rel="next"> 
							{next && !prev ? 'Get started' : 'Next'}
							<svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11">
									<g fill="none" fillRule="evenodd">
										<g fill="#FBB03B" className={`${styles.arrowfill} arrowfill`}>
											<g>
												<g>
													<path d="M188.638 11.04l-2.44-2.452c-.336-.339-.882-.339-1.218 0-.337.339-.337.887 0 1.226l3.91 3.932c.04.04.085.076.132.108.02.013.04.022.06.033.03.016.058.034.089.047.025.01.05.016.077.024.028.009.055.02.084.025.044.009.089.012.133.013.012 0 .023.004.035.004.013 0 .026-.004.039-.004.043-.002.086-.004.129-.013.03-.006.059-.017.088-.027.025-.007.05-.013.073-.022.033-.014.064-.033.095-.05.018-.01.036-.018.053-.03.048-.032.093-.068.133-.109l3.91-3.931c.169-.17.253-.391.253-.613 0-.222-.084-.444-.253-.613-.336-.339-.882-.339-1.219 0l-2.439 2.453V5.867c0-.479-.386-.867-.862-.867s-.862.388-.862.867v5.174z" transform="translate(-535 -751) translate(35 45) translate(315 702) rotate(-90 189.5 9.5)"/>
												</g>
											</g>
										</g>
									</g>
								</svg> 
							</Link></div>
						}
					</nav> 

		
				</div>
			</div>
			{coachMolarImage && tipText && <div className={`${styles.coachmolartip} coachmolartip ${plaqusterCssClass}`}>
				<div className={`${styles.tipwrap} tipwrap`}>
					<div className={styles.coachmolarimagewrap}>
						<FluidImage image={coachMolarImage} passedClass={styles.coachmolarimage} loading={"eager"} />
					</div>
				</div>
				<div className={`${styles.tip} tip`}>
						{tipText}
				</div>
			</div>}
		</Layout>
	);
};

export const query = graphql`
	query($id: String!) {
		plaquster: wpPlaquster(id: { eq: $id }) {
			id
			title
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
			ACFPlaquster {
				plaqusterTitle 
				plaqusterContent {
					textContent
					plaqusterImage {
						altText
						localFile {
							childImageSharp {
								fluid(maxWidth: 420){
									...GatsbyImageSharpFluid_withWebp
								}
							}
							publicURL
							extension
						}
					}
					learnMoreLink {
						target
						title
						url
					}
					coachMolarTip {
						tipText
						coachMolarImage {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 160){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 420){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		introBGMobile: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 699) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGTablet: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 550) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGDesktop: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 840) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGXL: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 1200) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		rightArrowYellow: file(name: {eq: "arrow"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		arrowBlue: file(name: {eq: "arrow_blue"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
	}
`;